import { render, staticRenderFns } from "./XrayDocTemplateStudy.vue?vue&type=template&id=779ce205&scoped=true&"
import script from "./XrayDocTemplateStudy.vue?vue&type=script&lang=js&"
export * from "./XrayDocTemplateStudy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779ce205",
  null
  
)

export default component.exports